
import { defineComponent, ref, watch, onMounted, nextTick } from "vue";
import { message } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import * as API from "@/API/payment";
import { downloadFromStream } from '@/utils/payment/downloadFile';

// import { GlobalWorkerOptions, getDocument, PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist'
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import useWait from "@/utils/payment/useWait";

function blobToRaw(blob: Blob) {
  return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        const dataURL = e.target?.result as string
        if (dataURL) {
          const BASE64_MARKER = ';base64,'
          const base64DataIndex = dataURL.indexOf(BASE64_MARKER) + BASE64_MARKER.length
          const base64Data = dataURL.substring(base64DataIndex)

          const raw = atob(base64Data)
          resolve(raw)
        }
      }
      fileReader.readAsDataURL(blob)
      fileReader.onerror = () => {
        reject(new Error('blobToBase64 error'))
      }
  })
}

function blobToURL(blob: Blob, type = 'application/pdf') {
  return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        const dataURL = e.target?.result as string
        if (dataURL) {
          const BASE64_MARKER = ';base64,'
          const base64DataIndex = dataURL.indexOf(BASE64_MARKER) + BASE64_MARKER.length
          const data = atob(dataURL.substring(base64DataIndex))

          const buffer = new ArrayBuffer(data.length);
          const u8a = new Uint8Array(buffer);
          for (let i = 0; i < data.length; i++) {
            u8a[i] = data.charCodeAt(i);
          }
          const blob = new Blob([buffer], {type});
          resolve(URL.createObjectURL(blob))
        }
      }
      fileReader.readAsDataURL(blob)
      fileReader.onerror = () => {
        reject(new Error('blobToBase64 error'))
      }
  })
}

export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fileId: {
      required: true,
      type: String,
      default: ''
    },
    dealerDetailApproveId: {
      required: true,
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const wait = useWait()
    const _visible = useModel(props, "visible");
    const handleCancel = () => {
      _visible.value = false;
    };
    const approveClick = () => {
      const params = {
        dealerDetailApproveId: props.dealerDetailApproveId
      }
      API.salesBreakDownApprove(params).then((res: any) => {
        if (res.code === '0') {
          context.emit('on-approve')
          message.success('Sucessfully Approve!')
          _visible.value = false;
        } else {
          message.error(res.message)
        }
      });
    };
    const rejectVisible = ref(false)
    const comment = ref('')
    const commentList = ref<string[]>()
    API.salesBreakDownCommentList().then((data: any) => {
      commentList.value = data
    })
    const openReject = () => {
      rejectVisible.value = true
    }
    const rejectCancel = () => {
      comment.value = ''
      rejectVisible.value = false
    }
    const rejectClick = () => {
      const params = {
        dealerDetailApproveId: props.dealerDetailApproveId,
        comment: comment.value
      }
      API.salesBreakDownReject(params).then((res: any) => {
        if (res.code === '0') {
          context.emit('on-reject')
          message.success('Sucessfully Reject!')
          rejectVisible.value = false
          _visible.value = false
          comment.value = ''
        } else {
          message.error(res.message)
        }
      });
    };
    const downloadClick = async () => {
      const res = await API.downloadAttachmentWatermark(props.fileId)
      downloadFromStream(res)
    }
    const spinning = ref(true)
    const previewType = ref('')
    const imageSrc = ref('')
    const pdfSrc = ref('')

    const preview = () => {
        API.getDealerBreakdownAttachmentFile(props.fileId).then(data => {
        const url = decodeURIComponent(data as unknown as string)
        const matches = url.match(/response-content-type=([^&]*)/)
        if (matches && matches.length === 2) {
          if (matches[1] === 'application/pdf') {
            previewType.value = 'pdf'

            API.downloadAttachment(props.fileId).then(res => {
              return blobToURL(res.data)
            }).then(data => {
              pdfSrc.value = data
              spinning.value = false
            })
          } else {
            previewType.value = 'image'
            imageSrc.value = data
            spinning.value = false
          }
        }
      })
    }

    // 关闭弹窗
    watch(_visible, (val) => {
      spinning.value = true
      previewType.value = ''
      imageSrc.value = ''
      if (val) {
        wait.then(() => {
          preview()
        })
      }
    })

    onMounted(() => {
      wait.toRun && wait.toRun()
    })

    return {
      spinning,
      previewType,
      pdfSrc,
      imageSrc,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      _visible,
      handleCancel,
      approveClick,
      rejectClick,
      downloadClick,
      rejectVisible,
      openReject,
      rejectCancel,
      comment,
      commentList
    };
  },
});
