
import { defineComponent, reactive, ref, watch, computed } from "vue";
import useModel from "@/hooks/useModel";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    commentData: {
      required: true,
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const _visible = useModel(props, "visible");
    const commentColumns = computed(() => {
      return [
        { title: "Comment", dataIndex: "comment" },
        { title: "Date", dataIndex: "date" }
      ]
    })
    // type TableData = {
    //   comment: string;
    //   date: string;
    // }
    // const commentData = ref<TableData[]>()
    const handleCancel = () => {
      _visible.value = false;
    }
    return {
      _visible,
      handleCancel,
      commentColumns
    };
  },
});
